import clsx from "clsx";
import React from "react";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack/v-stack";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./editorial-details.css";

export type EditorialDetailObject = {
    isSponsored: boolean;
    title: string;
    caption: string;
    url: string;
};
interface EditorialDetailsProps {
    variant?: "primary" | "secondary" | "compact";
    details: EditorialDetailObject;
    isBodyClickable?: boolean;
    onCtaClick: () => void;
}

/**
 * Design System
 * @name EditorialDetails
 * @version 1.0
 * @design https://www.figma.com/file/EJXteyeEMjMgKETDQiZDli/Design-Sprint%3A-FY24-Home?type=design&node-id=3767-78162&mode=design&t=Otf1m3aYXI7YYf4b-4
 */
export function EditorialDetails({
    variant = "primary",
    details,
    isBodyClickable,
    onCtaClick,
}: EditorialDetailsProps): React.ReactElement {
    const { isSponsored, title, caption, url } = details;
    const isPrimary = variant === "primary";
    const isSecondary = variant === "secondary";
    const isCompact = variant === "compact";

    const bodyText = (
        <Text as="p" textStyle={isCompact ? "body-3-small" : "body-2-v2"} className={styles.body}>
            {caption}
        </Text>
    );

    return (
        <section
            className={clsx({ [styles.wrapper]: !isCompact })}
            data-testid={"editorial-details"}
        >
            <div
                className={clsx(styles.content, {
                    [styles.secondaryDesktopContent]: isSecondary,
                    [styles.primaryContent]: isPrimary,
                })}
            >
                <VStack spacing={isPrimary ? "lg" : "xxs"}>
                    <VStack spacing={"xs"}>
                        {isSponsored && (
                            <Text textStyle={"caption-3"} className={styles.sponsored}>
                                {gettext("general.sponsored_label")}
                            </Text>
                        )}

                        <Heading
                            upperCase={isCompact}
                            textStyle={
                                isCompact
                                    ? "title-2-v2"
                                    : {
                                          lg: "large-title-v2",
                                          md: "large-title-v2",
                                          sm: "large-title-2",
                                      }
                            }
                        >
                            {title}
                        </Heading>
                    </VStack>
                    {isBodyClickable ? (
                        <a href={url} data-testid={"body-text"}>
                            {bodyText}
                        </a>
                    ) : (
                        bodyText
                    )}
                </VStack>
                <div
                    data-testid={isPrimary ? "explore-now-button" : "read-more-button"}
                    className={clsx({ [styles.buttonWrapper]: isPrimary })}
                >
                    <Button
                        href={url}
                        title={
                            isPrimary
                                ? gettext("general.explore_now")
                                : gettext("general.read_more")
                        }
                        width={isPrimary ? "full" : "fit-content"}
                        variant={isPrimary ? "primary" : "secondary"}
                        onClick={onCtaClick}
                        data-testid={"cta-button"}
                    />
                </div>
            </div>
        </section>
    );
}
