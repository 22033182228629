import clsx from "clsx";
import React, { useState } from "react";
import { RelatedProductsSidebar } from "web/react/components/related-products-sidebar/related-products-sidebar";
import { Accordion } from "web/react/emo/accordion";
import { Stack } from "web/react/emo/shared/components/stack";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import {
    ProductPurchaseType,
    useInStockProductPageContext,
} from "web/react/pages/product/in-stock/in-stock-product-page/in-stock-product-page.context";
import { MoreLikeThis } from "web/react/pages/product/in-stock/in-stock-product-page/more-like-this/more-like-this";
import { ComparePriceLink } from "web/react/pages/product/in-stock/in-stock-product-page/product-price-comparison/product-price-comparison";
import { ProductImageGallery } from "web/react/pages/product/in-stock/product-image-gallery/product-image-gallery";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import * as styles from "./product-area.css";
import { ProductInfo } from "./product-info";

export function ProductArea(): React.ReactElement {
    const { isMobileViewport } = useDomViewport();

    const {
        designer,
        product,
        buyOptions,
        activeProduct: { longDescription },
        relatedProductsSidebar,
        productPurchaseType,
    } = useInStockProductPageContext();

    const isExpressCheckout = productPurchaseType === ProductPurchaseType.EXPRESS_CHECKOUT;
    const showGalleryOnLeft = environment.getFeature("cw_show_gallery_on_left");

    const [openEventSent, setOpenEventSet] = useState(false);

    const accordionContents = [
        {
            title: gettext("in_stock_product.details"), // Product details
            children: (
                <Text textStyle="body-3-small" color="secondary">
                    {longDescription}
                </Text>
            ),
            onClick: () => {
                if (!openEventSent) {
                    analytics.event("product area", "click", "open-details");
                    setOpenEventSet(true);
                }
            },
        },
        ...(product.designer_category_url
            ? [
                  {
                      title: gettext("in_stock_product.designer_category_link", {
                          designer: designer.name,
                      }), // Shop more {designer.name}
                      href: product.designer_category_url,
                      onClick: () => {
                          analytics.event("buy_area", "clicked", "pdp_more_designer_btn");
                      },
                  },
              ]
            : []),
    ];

    return (
        <Stack
            align={{ sm: "center", md: "center", lg: "start" }}
            direction={{ sm: "column-reverse", md: "column-reverse", lg: "row" }}
            spacing={isMobileViewport ? "md" : "lg"}
            justify="space-between"
        >
            {showGalleryOnLeft && (
                <div className={styles.galleryParent}>
                    <View className={styles.imageGallery}>
                        <ProductImageGallery
                            images={product?.images?.map(({ alt_text, full_size_url, id }, i) => ({
                                id: id || `ProductImageGallery-${i.toString()}`, //  avoid using indices as keys
                                altText: alt_text,
                                url: full_size_url,
                            }))}
                            productId={product.product_id}
                        />
                    </View>
                </div>
            )}

            <View className={styles.productInfoWrapper}>
                <VStack spacing="md">
                    <ProductInfo />
                    {!isExpressCheckout && buyOptions.length > 1 && <ComparePriceLink />}
                    <div className={"hidden-desktop"}>
                        {relatedProductsSidebar && (
                            <MoreLikeThis moreLikeThisProducts={relatedProductsSidebar} />
                        )}
                    </div>

                    <div data-testid="accordion-contents">
                        <View>
                            <Accordion items={accordionContents} />
                        </View>
                    </div>
                </VStack>
            </View>

            {!showGalleryOnLeft && (
                <div className={styles.galleryParent}>
                    <View className={styles.imageGallery}>
                        <ProductImageGallery
                            images={product?.images?.map(({ alt_text, full_size_url, id }, i) => ({
                                id: id || `ProductImageGallery-${i.toString()}`, //  avoid using indices as keys
                                altText: alt_text,
                                url: full_size_url,
                            }))}
                            productId={product.product_id}
                        />
                    </View>
                </div>
            )}

            {relatedProductsSidebar && (
                <div className={styles.sidebarParent}>
                    <View marginLeft={"sm"} marginTop={"sm"} className={styles.sidebarWrapper}>
                        <RelatedProductsSidebar
                            relatedProductsSidebar={relatedProductsSidebar}
                            variant="vertical"
                            className={clsx(styles.sidebar, "affiliateSidebar")}
                            isEmo
                        />
                    </View>
                </div>
            )}
        </Stack>
    );
}
